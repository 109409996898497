import React from "react";
import styles from "./footer.module.scss";
import logo from "../../images/logo.svg";
import { YouTube, Instagram, Twitter } from "@material-ui/icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div className="container">
        <img src={logo} alt="Udevs" />
        <div className={styles.navigation}>
          <div>
            <h3>{t("us")}</h3>
            <AnchorLink className={styles.itemLink} href="/directs">
              {t("direction")}
            </AnchorLink>
            <AnchorLink className={styles.itemLink} href="/team">
              {t("command")}
            </AnchorLink>
            <AnchorLink className={styles.itemLink} href="/tools">
              {t("tools")}
            </AnchorLink>
            <AnchorLink className={styles.itemLink} href="/clients">
              {t("clients")}
            </AnchorLink>
          </div>
          <div>
            <h3>{t("service")}</h3>
            <AnchorLink href="/mobile">{t("mobile")}</AnchorLink>
            <AnchorLink href="/erp">{t("erp")}</AnchorLink>
            <AnchorLink href="/design">{t("ui")}</AnchorLink>
            <AnchorLink href="/consulting">{t("consulting")}</AnchorLink>
            <AnchorLink href="/optimization">{t("optimisation")}</AnchorLink>
          </div>
          <div>
            <h3>{t("portfolio")}</h3>
            <AnchorLink href="/delever">Delever</AnchorLink>
            <AnchorLink href="/smsuz">Sms.uz</AnchorLink>
            <AnchorLink href="/goodzone">Goodzone</AnchorLink>
            <AnchorLink href="/iman">Iman</AnchorLink>
          </div>
        </div>
        <div className={styles.copyright}>
          <p>© {currentYear} Udevs. All rights reserved</p>
          <div className={styles.actions}>
            <a href="/" className={styles.icon}>
              <Instagram />
            </a>
            <a href="/" className={styles.icon}>
              <Twitter />
            </a>
            <a href="/" className={styles.icon}>
              <YouTube />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
