import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import styles from "./header.module.scss";
import logo from "../../images/logo.svg";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import AnchorLink from "react-anchor-link-smooth-scroll";
import angleDown from "../../images/angle-down.svg";
import mobileIcon from "../../images/dropdown/mobile.svg";
import desktopIcon from "../../images/dropdown/desktop.svg";
import erpIcon from "../../images/dropdown/erp.svg";
import designIcon from "../../images/dropdown/design.svg";
import optimizationIcon from "../../images/dropdown/optimization.svg";
import { Menu } from "@material-ui/icons";
import ResponsiveHeader from "../responsiveHeader/responsiveHeader";
import { projs } from "../service";
import ScrollSpy from "react-scrollspy";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import russia from "../../images/russia.svg";
import unitedKingdom from "../../images/united_kingdom.svg";
import { navigate } from "gatsby-link";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Header = ({ siteTitle }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);

  const container = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.4,
      },
    },
  };
  return (
    <>
      {/* <HideOnScroll {...props}>
        <AppBar color='inherit'>
          <Toolbar> */}
      <motion.header
        variants={container}
        initial="hidden"
        animate={controls1}
        className={styles.header}
        ref={ref1}
      >
        <div className="container">
          <nav className={styles.navbar}>
            <AnchorLink
              href="/home"
              to="/"
              offset={72}
              className={styles.logoWrapper}
            >
              <img src={logo} alt={siteTitle} />
            </AnchorLink>
            <button className={styles.menuBtn} onClick={() => setOpen(true)}>
              <Menu />
            </button>
            <ScrollSpy
              items={[
                "directs",
                "team",
                "services",
                "tools",
                "clients",
                "portfolio",
              ]}
              currentClassName="is-current"
              offset={-10}
              className={styles.navList}
            >
              <li className={styles.navListItem}>
                <AnchorLink className={styles.itemLink} href="/directs">
                  {t("direction")}
                </AnchorLink>
              </li>
              <li className={styles.navListItem}>
                <AnchorLink className={styles.itemLink} href="/team">
                  {t("command")}
                </AnchorLink>
              </li>

              <li className={styles.navListItem}>
                <AnchorLink href="/services" className={styles.dropdownTitle}>
                  {t("services")} <img src={angleDown} alt="Angle Down" />
                </AnchorLink>
                <ul className={styles.dropdown}>
                  <p className={styles.title}>{t("services")}</p>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/mobile">
                      <img src={mobileIcon} alt="Mobile development" />
                      <span>{t("mobile")}</span>
                    </AnchorLink>
                  </li>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/erp">
                      <img src={erpIcon} alt="ERP systems" />
                      <span>{t("erp")}</span>
                    </AnchorLink>
                  </li>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/design">
                      <img src={designIcon} alt="UX/UI design" />
                      <span>{t("ui")}</span>
                    </AnchorLink>
                  </li>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/consulting">
                      <img src={desktopIcon} alt="IT Consulting" />
                      <span>{t("consulting")}</span>
                    </AnchorLink>
                  </li>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/optimization">
                      <img src={optimizationIcon} alt="Optimization" />
                      <span>{t("optimisation")}</span>
                    </AnchorLink>
                  </li>
                </ul>
              </li>
              <li className={styles.navListItem}>
                <AnchorLink className={styles.itemLink} href="/tools">
                  {t("tools")}
                </AnchorLink>
              </li>
              <li className={styles.navListItem}>
                <AnchorLink className={styles.itemLink} href="/clients">
                  {t("clients")}
                </AnchorLink>
              </li>
              <li className={styles.navListItem}>
                <AnchorLink href="/portfolio" className={styles.dropdownTitle}>
                  {t("portfolio")} <img src={angleDown} alt="Angle Down" />
                </AnchorLink>
                <ul className={styles.dropdown}>
                  <p className={styles.title}>{t("portfolio")}</p>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/delever">
                      <img src={projs.delever} alt="Delever" />
                      <span>Delever</span>
                    </AnchorLink>
                  </li>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/smsuz">
                      <img src={projs.smsuz} alt="SmsUz" />
                      <span>Sms.uz</span>
                    </AnchorLink>
                  </li>
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/goodzone">
                      <img src={projs.goodzone} alt="Goodzone" />
                      <span>Goodzone</span>
                    </AnchorLink>
                  </li>
                  {/* <li className={styles.dropdownItem}>
                    <AnchorLink href='/najot'>
                      <img src={projs.najot} alt='Najot talim' />
                      <span>Na’jot ta’lim</span>
                    </AnchorLink>
                  </li> */}
                  <li className={styles.dropdownItem}>
                    <AnchorLink href="/iman">
                      <img src={projs.iman} alt="Iman" />
                      <span>Iman</span>
                    </AnchorLink>
                  </li>
                  {/* <li className={styles.dropdownItem}>
                    <AnchorLink href='/voxe'>
                      <img src={projs.voxe} alt='Voxe' />
                      <span>Voxe</span>
                    </AnchorLink>
                  </li> */}
                </ul>
              </li>
              <li className={styles.navListItem}>
                <AnchorLink className={styles.dropdownTitle}>
                  {t("language")} <img src={angleDown} alt="Language" />
                </AnchorLink>
                <ul className={styles.languageDropdown}>
                  <li
                    className={styles.languageDropdownItem}
                    onClick={(e) => {
                      i18n
                        .changeLanguage("ru")
                        .then(() => {})
                        .catch((err) => console.log(err));
                    }}
                  >
                    <AnchorLink>
                      <img
                        src={russia}
                        alt="Russian"
                        className={styles.languageIcon}
                      />
                      <span>Рус</span>
                    </AnchorLink>
                  </li>
                  <li
                    className={styles.languageDropdownItem}
                    onClick={(e) => {
                      i18n
                        .changeLanguage("en")
                        .then(() => {})
                        .catch((err) => console.log(err));
                    }}
                  >
                    <AnchorLink>
                      <img
                        src={unitedKingdom}
                        alt="United kingdom"
                        className={styles.languageIcon}
                      />
                      <span>Eng</span>
                    </AnchorLink>
                  </li>
                </ul>
              </li>
              <div className="call-to-action">
                <AnchorLink className={styles.itemLink} href="/contact">
                  {t("toCall")}
                </AnchorLink>
              </div>
            </ScrollSpy>
          </nav>
        </div>
      </motion.header>
      {/*</Toolbar>
        </AppBar>
      </HideOnScroll> */}
      {/* {open && ( */}
      <ResponsiveHeader open={open} closeMenu={() => setOpen(false)} />
      {/* // )} */}
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
