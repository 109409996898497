import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ lang, meta }) {

  // useEffect(() => {
  //   window.replainSettings = { id: '0c295654-4c53-4713-b363-84825d37f80a' };
  //   (function(u){var s=document.createElement('script');s.async=true;s.src=u;
  //   var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
  //   })('https://widget.replain.cc/dist/client.js');
  // }, [])
  
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            image
          }
        }
      }
    `
  )

  const metaDescription = site.siteMetadata?.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `keywords`,
          content: site.siteMetadata?.keywords
        },
        {
          property: `og:title`,
          content: defaultTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: 'og:image',
          content: site.siteMetadata?.image
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: 'twitter:image',
          content: site.siteMetadata?.image
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author
        },
        {
          name: `twitter:title`,
          content: defaultTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {/* <script src='//code.jivo.ru/widget/MEzPBrI38T' async></script> */}
      {/* <script>
      window.replainSettings = { id: '0c295654-4c53-4713-b363-84825d37f80a' };
              (function(u){var s=document.createElement('script');s.async=true;s.src=u;
              var x=document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
              })('https://widget.replain.cc/dist/client.js');
        </script> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
