// projects
import delever_proj from "../images/projs/delever.svg";
import goodzone_proj from "../images/projs/goodzone.svg";
import iman_proj from "../images/projs/iman.svg";
import najot_proj from "../images/projs/najot.svg";
import smsuz_proj from "../images/projs/smsuz.svg";
import voxe_proj from "../images/projs/voxe.svg";

// clients
import akfa from "../images/clients/akfaMedline.svg";
import cspace from "../images/clients/cspace.svg";
import delever_svg from "../images/clients/delever.svg";
import fonon from "../images/clients/fonon.svg";
import hamkor from "../images/clients/hamkorbank.svg";
import hoshimov from "../images/clients/hoshimov.svg";
import iman_svg from "../images/clients/iman.svg";
import maxtrack from "../images/clients/maxTrack.svg";

// import najot from '../images/clients/najot.svg'
import shef from "../images/clients/shefBurger.svg";
// import voxe_svg from '../images/clients/voxe.svg'
import workly from "../images/clients/workly.svg";
import mytaxi from "../images/clients/myTaxi.svg";
import z from "../images/clients/rabota.svg";
import goodzone_svg from "../images/clients/gz.svg";
import smsuz_svg from "../images/clients/smsuz.svg";

// steps
import step1 from "../images/steps/step1.svg";
import step2 from "../images/steps/step2.svg";
import step3 from "../images/steps/step3.svg";
import step4 from "../images/steps/step4.svg";
import step5 from "../images/steps/step5.svg";
import step_line from "../images/steps/line.svg";

// mobile development
import ios from "../images/ios.svg";
import android from "../images/android.svg";
import crossplatform from "../images/crossplatform.svg";
import swift from "../images/swift.svg";
import kotlin from "../images/kotlin.svg";
import flutter from "../images/flutter.svg";
import mobileApp from "../images/mobile_dev.png";

// erp systems
import crm from "../images/crm.svg";
import eLearning from "../images/eLearning.svg";
import eCommerce from "../images/eCommerce.svg";
import pos from "../images/pos.svg";
import email from "../images/email.svg";
import warehouse from "../images/warehouse.svg";
import desktop from "../images/desktop.png";

// ux/ui design
import ux from "../images/ux.svg";
import ui from "../images/ui.svg";
import prototyping from "../images/prototyping.svg";
import mobileDesign from "../images/mobile_design.svg";
import webDesign from "../images/web_design.svg";
import atomicDesign from "../images/atomic_design.svg";
import figma from "../images/figma.svg";
import sketch from "../images/sketch.svg";
import lottie from "../images/lottie.svg";
import illustrator from "../images/illustrator.svg";
import design from "../images/design.png";

// optimization
import architecture from "../images/architecture.svg";
import autoTesting from "../images/autoTesting.svg";
import stressTesting from "../images/stressTesting.svg";
import loadTesting from "../images/loadTesting.svg";
import devops from "../images/devops.svg";
import cloud from "../images/cloud.svg";
import cicd from "../images/cicd.svg";
import system from "../images/system.png";

// delever
import deliveryIcon from "../images/projects/delivery.svg";
import deleverApp from "../images/projects/delever_app.png";
import website from "../images/projects/website.svg";
import admin from "../images/projects/admin.svg";
import deleverBrand from "../images/projects/delever.svg";

// smsuz
import smsuzBrand from "../images/projects/smsuz.svg";
import smsuzApp from "../images/projects/smsuz_app.png";
import notification from "../images/projects/notification.svg";

// goodzone
import gzBrand from "../images/projects/logo.png";
import eCommerceIcon from "../images/projects/eCommerce.svg";
import gzApp from "../images/projects/goodzone_app.png";

// Najot Ta'lim
import najotBrand from "../images/projects/najot.svg";
import study from "../images/projects/study.svg";
import najotApp from "../images/projects/najot_app.png";

// iman
import imanBrand from "../images/projects/iman.svg";
import banking from "../images/projects/bank.svg";
import imanApp from "../images/projects/imanApp.png";

// voxe
import voxeBrand from "../images/projects/voxe.svg";
import media from "../images/projects/media.svg";
import voxeApp from "../images/projects/voxeApp.png";

export const clients = {
  title: "Наши клиенты",
  pictures: [
    akfa,
    cspace,
    delever_svg,
    fonon,
    hamkor,
    hoshimov,
    iman_svg,
    maxtrack,
    // najot,
    shef,
    // voxe_svg,
    mytaxi,
    workly,
    z,
    goodzone_svg,
    smsuz_svg,
  ],
};

export const projs = {
  delever: delever_proj,
  goodzone: goodzone_proj,
  iman: iman_proj,
  najot: najot_proj,
  smsuz: smsuz_proj,
  voxe: voxe_proj,
};

export const steps = {
  line: step_line,
  childs: [
    {
      title: "Cвязь",
      about: "Отправьте нам свой запрос на проект или идею проекта.",
      img: step1,
    },
    {
      title: "Анализ",
      about: "Мы свяжемся с вами, чтобы уточнить ваши требования к проекту.",
      img: step2,
    },
    {
      title: "Предложение",
      about:
        "Мы предоставим вам нашу бесплатную, не имеющую обязательной силы заявку.",
      img: step3,
    },
    {
      title: "Команда",
      about: "Мы выделяем команду под ваши требования.",
      img: step4,
    },
    {
      title: "Старт",
      about: "Вы познакомитесь с командой, и мы начнем.",
      img: step5,
    },
  ],
};

export const mobileDev = {
  title: "Мобильная разработка",
  id: "mobile",
  description:
    "В сотрудничестве со стартапами мы научились создавать творческий и функциональный пользовательский интерфейс для мобильных приложений.",
  image: mobileApp,
  services: [
    {
      icon: ios,
      name: "iOS",
    },
    {
      icon: android,
      name: "Android",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
  ],
  techstack: [
    {
      icon: swift,
      name: "Swift",
    },
    {
      icon: kotlin,
      name: "Kotlin",
    },
    {
      icon: flutter,
      name: "Flutter",
    },
  ],
};

export const erpSystems = {
  title: "ERP cистемы",
  id: "erp",
  description: "IT Системы любого уровня сложности в удобные для вас сроки.",
  image: desktop,
  services: [
    {
      icon: crm,
      name: "CRM",
    },
    {
      icon: eLearning,
      name: "eLearning",
    },
    {
      icon: eCommerce,
      name: "E-Commerce",
    },
    {
      icon: pos,
      name: "POS",
    },
    {
      icon: email,
      name: "Sms / Email",
    },
    {
      icon: warehouse,
      name: "Warehouse",
    },
  ],
  techstack: [],
};

export const uxui = {
  title: "UI / UX Дизайн",
  id: "design",
  description:
    "Наша компания придерживается подхода к дизайну, ориентированного на человека..",
  image: design,
  services: [
    {
      icon: ux,
      name: "UX",
    },
    {
      icon: ui,
      name: "UI",
    },
    {
      icon: prototyping,
      name: "Prototyping",
    },
    {
      icon: mobileDesign,
      name: "Mobile Design",
    },
    {
      icon: webDesign,
      name: "Web Design",
    },
    {
      icon: atomicDesign,
      name: "Atomic Design",
    },
  ],
  techstack: [
    {
      icon: figma,
      name: "Figma",
    },
    {
      icon: sketch,
      name: "Sketch",
    },
    {
      icon: lottie,
      name: "Lottie",
    },
    {
      icon: illustrator,
      name: "Illustrator",
    },
  ],
};

export const optimization = {
  title: "Оптимизация инфраструктуры",
  id: "optimization",
  description:
    "Наши опытные специалисты помогут оптимизировать вашу инфраструктуру.",
  image: system,
  services: [
    {
      icon: architecture,
      name: "Architecture",
    },
    {
      icon: autoTesting,
      name: "Auto testing",
    },
    {
      icon: stressTesting,
      name: "Stress testing",
    },
    {
      icon: loadTesting,
      name: "Load testing",
    },
    {
      icon: devops,
      name: "Devops",
    },
    {
      icon: cloud,
      name: "Cloud",
    },
    {
      icon: cicd,
      name: "CI / CD",
    },
  ],
  techstack: [],
};

export const delever = {
  title: "Delever",
  brand: deleverBrand,
  id: "delever",
  type: {
    icon: deliveryIcon,
    name: "Delivery",
    color: "#FF5722",
    background: "rgba(255,87,34,0.2)",
  },
  description:
    "<p><strong>Delever —</strong> Автоматизация службы доставки ориентированая как на потребителей так и на рестораны.</p>",
  image: deleverApp,
  services: [
    {
      icon: website,
      name: "Website",
    },
    {
      icon: admin,
      name: "Admin panel",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
    {
      icon: webDesign,
      name: "Web design",
    },
    {
      icon: mobileDesign,
      name: "Mobile design",
    },
  ],
};

export const smsuz = {
  title: "Smsuz",
  brand: smsuzBrand,
  id: "smsuz",
  type: {
    icon: notification,
    name: "Notification",
    color: "#4473E5",
    background: "rgba(68,115,229,0.2)",
  },
  description:
    "<p><strong>Smsuz.uz —</strong> Это платформа для массовых смс рассылок.</p>",
  image: smsuzApp,
  services: [
    {
      icon: website,
      name: "Website",
    },
    {
      icon: admin,
      name: "Admin panel",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
  ],
};

export const goodzone = {
  title: "Goodzone",
  brand: gzBrand,
  id: "goodzone",
  type: {
    icon: eCommerceIcon,
    name: "E-Commerce",
    color: "#F50000",
    background: "rgba(245,0,0,0.2)",
  },
  description:
    "<p><strong>Goodzone —</strong> Интернет магазин бытовой техники в Узбекистане.</p>",
  image: gzApp,
  services: [
    {
      icon: website,
      name: "Website",
    },
    {
      icon: admin,
      name: "Admin panel",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
    {
      icon: webDesign,
      name: "Web design",
    },
    {
      icon: mobileDesign,
      name: "Mobile design",
    },
  ],
};

export const najotTalim = {
  title: "Najot talim",
  brand: najotBrand,
  id: "najotTalim",
  type: {
    icon: study,
    name: "Study",
    color: "#BB8D5B",
    background: "rgba(187,141,91,0.2)",
  },
  description:
    "<p><strong>Najot Ta’lim —</strong> Эта образовательная платформа, направленные на освоение современных профессий и навыков, что помогут вам в будущем.</p>",
  image: najotApp,
  services: [
    {
      icon: website,
      name: "Website",
    },
    {
      icon: admin,
      name: "Admin panel",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
  ],
};

export const iman = {
  title: "Iman",
  brand: imanBrand,
  id: "iman",
  type: {
    icon: banking,
    name: "Finance",
    color: "#01CAB0",
    background: "rgba(1,202,176,0.2)",
  },
  description:
    "<p><strong>Iman —</strong> это платформа взаимного финансирования, основанная на принципах «Исламских финансов». Здесь встречаются покупатели, продавцы и инвесторы.</p>",
  image: imanApp,
  services: [
    {
      icon: mobileDesign,
      name: "Mobile design",
    },
    {
      icon: admin,
      name: "Admin panel",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
  ],
};

export const voxe = {
  title: "Voxe",
  brand: voxeBrand,
  id: "voxe",
  type: {
    icon: media,
    name: "Media",
    color: "#FF5722",
    background: "rgba(255,87,34,0.2)",
  },
  description:
    "<p><strong>Voxe —</strong> это интернет-сервис о кино, позволяющий смотреть разнообразные удостоенные наград фильмы, сериалы, документальные фильмы и многое другое!</p>",
  image: voxeApp,
  services: [
    {
      icon: website,
      name: "Website",
    },
    {
      icon: admin,
      name: "Admin panel",
    },
    {
      icon: crossplatform,
      name: "Crossplatform",
    },
    {
      icon: webDesign,
      name: "Web design",
    },
    {
      icon: mobileDesign,
      name: "Mobile design",
    },
  ],
};
