import React from "react";
import styles from "./responsiveHeader.module.scss";
import { Instagram, Twitter, YouTube } from "@material-ui/icons";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import logo from "../../images/logo.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
// import cancel from '../../images/cancel.png'
import { useTranslation } from "react-i18next";

export default function ResponsiveHeader({ closeMenu, open }) {
  const { t } = useTranslation();

  return (
    <div
      className={styles.mobileHeader}
      style={{
        // opacity: open ? 1 : 0,
        opacity: 1,
        // right: open && 0,
        // width: open ? '100%' : 0,
        transform: open && "translate(0)",
      }}
    >
      <div className={styles.top}>
        <div className={styles.logoWrapper}>
          <img src={logo} alt="Udevs brand" />
        </div>
        <button className={styles.close} onClick={closeMenu}>
          {/* <img src={cancel} alt={`udevs cancel`} /> */}
          <CloseOutlinedIcon />
        </button>
      </div>
      <ul className={`${styles.list}`}>
        <li className={styles.list_item}>
          <AnchorLink
            onClick={closeMenu}
            href="/mobile"
            style={{
              textDecoration: `none`,
            }}
            className={styles.list_item + ` hoverable_btn`}
          >
            {t("service")}
          </AnchorLink>
        </li>
        <li className={styles.list_item}>
          <AnchorLink
            onClick={closeMenu}
            href="/clients"
            style={{
              textDecoration: `none`,
            }}
            className={styles.list_item + ` hoverable_btn`}
          >
            {t("clients")}
          </AnchorLink>
        </li>
        <li className={styles.list_item}>
          <AnchorLink
            onClick={closeMenu}
            href="/team"
            style={{
              textDecoration: `none`,
            }}
            className={styles.list_item + ` hoverable_btn`}
          >
            {t("command")}
          </AnchorLink>
        </li>
      </ul>
      <div className={styles.bottom}>
        <div className="call-to-action fullWidth large">
          <AnchorLink onClick={closeMenu} href="/contact">
            {t("toCall")}
          </AnchorLink>
        </div>
        <div className={styles.social}>
          <span className={styles.item}>
            <Instagram />
          </span>
          <span className={styles.item}>
            <Twitter />
          </span>
          <span className={styles.item}>
            <YouTube />
          </span>
        </div>
      </div>
    </div>
  );
}
