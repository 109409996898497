import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Footer from '../footer/footer'
import Header from '../header/header'
import './layout.css'
import './global.css'
import 'react-notifications/lib/notifications.css'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../../i18n'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <I18nextProvider i18n={i18n}>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {children}
      <Footer />
    </I18nextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
